<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-18 11:12:28
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-09-08 09:45:44
-->
<template>
  <div class="messageList">
    <el-tabs v-model="activeName">
      <el-tab-pane label="常见问题" name="first">
        <template v-if="commonList.length">
          <el-link
            class="item"
            :underline="false"
            v-for="(item,index) in commonList"
            :key="'commonList'+index"
            @click.native="handleDetail(item.id,1)"
          >
            <div class="line_1">
              <p class="time">{{item.modifyDate}}</p>
              <p class="title text-ellipsis">{{item.title}}</p>
            </div>
            <div class="line_2 text-ellipsis" v-html="item.remark"></div>
          </el-link>
          <pagination
            :listLoading="commonLoading"
            @getList="getCommonList"
            @setLimit="commonForm.pageSize = $event"
            @setPage="commonForm.pageNo = $event"
            :currentPage="commonForm.pageNo"
            :pageSize="commonForm.pageSize"
            :total="commonForm.total"
          ></pagination>
        </template>
        <template v-else>
          <empty text="暂无常见问题相关列表"></empty>
        </template>
      </el-tab-pane>
      <el-tab-pane label="员工须知" name="second">
        <template v-if="knownList.length">
          <el-link
            class="item"
            :underline="false"
            v-for="(item,index) in knownList"
            :key="'knownList'+index"
            @click.native="handleDetail(item.id,1)"
          >
            <div class="line_1">
              <p class="time">{{item.modifyDate}}</p>
              <p class="title text-ellipsis">{{item.title}}</p>
            </div>
            <div class="line_2 text-ellipsis" v-html="item.remark"></div>
          </el-link>
          <pagination
            :listLoading="knownLoading"
            @getList="getKnownList"
            @setLimit="knownForm.pageSize = $event"
            @setPage="knownForm.pageNo = $event"
            :currentPage="knownForm.pageNo"
            :pageSize="knownForm.pageSize"
            :total="knownForm.total"
          ></pagination>
        </template>
        <template v-else>
          <empty text="暂无员工须知相关列表"></empty>
        </template>
      </el-tab-pane>
      <el-tab-pane label="签署通知" name="third">
        <template v-if="noticeList.length">
          <el-link
            class="item"
            :underline="false"
            v-for="(item,index) in noticeList"
            :key="'noticeList'+index"
            @click.native="handleDetail(item.id,0)"
          >
            <div class="line_1">
              <p class="time">{{item.modifyDate}}</p>
              <p class="title text-ellipsis">{{item.title}}</p>
            </div>
            <div class="line_2 text-ellipsis" v-html="item.content"></div>
          </el-link>
          <pagination
            :listLoading="noticeLoading"
            @getList="getNoticeList"
            @setLimit="noticeForm.size = $event"
            @setPage="noticeForm.current = $event"
            :currentPage="noticeForm.current"
            :pageSize="noticeForm.size"
            :total="noticeForm.total"
          ></pagination>
        </template>
        <template v-else>
          <empty text="暂无签署通知"></empty>
        </template>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import empty from "@/components/empty/index.vue";
import pagination from "@/components/pagination/index.vue";
import api from "@/api/list";
export default {
  name: "messageList",
  components: {
    pagination,
    empty,
  },
  data() {
    return {
      activeName: "first",
      commonList: [],
      knownList: [],
      noticeList: [],
      commonForm: {
        type: 119,
        pageSize: 10,
        pageNo: 1,
        total: 0,
      },
      knownForm: {
        type: 14,
        pageSize: 10,
        pageNo: 1,
        total: 0,
      },
      noticeForm: {
        size: 10,
        current: 1,
        total: 0,
      },
      commonLoading: true,
      knownLoading: true,
      noticeLoading: true,
    };
  },
  mounted() {
    this.getCommonList();
    this.getKnownList();
    this.getNoticeList();

    if (this.$route.query.tab) {
      this.activeName = this.$route.query.tab;
    }
  },
  methods: {
    handleDetail(id, type) {
      if (type == 1) {
        this.$router.push({
          path: "./messageDetail",
          query: { id, tab: this.activeName },
        });
      } else {
        this.$router.push({ path: "/userCenter/contracts" });
      }
    },
    getNoticeList() {
      api.newsList(this.noticeForm).then((res) => {
        this.noticeLoading = false;
        if (res) {
          this.noticeList = res.data.records;
          this.noticeForm.total = res.data.total;
        }
      });
    },
    getCommonList() {
      api.articleList(this.commonForm).then((res) => {
        this.commonLoading = false;
        if (res) {
          this.commonList = res.data.records;
          this.commonForm.total = res.data.total;
        }
      });
    },
    getKnownList() {
      api.articleList(this.knownForm).then((res) => {
        this.getKnownList = false;
        if (res) {
          this.knownList = res.data.records;
          this.knownForm.total = res.data.total;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.messageList {
  background: #fff;
  border-radius: 10px;
  padding: 12px 24px;
  min-height: 750px;
}
.item {
  display: block;
  padding: 12px;
  line-height: 30px;
  border-bottom: 1px solid #e4e7ed;

  &:hover {
    background: #f5f5f5;
  }

  .line_1 {
    p.title {
      width: 70%;
      font-size: 16px;
      color: #333;
    }

    p.time {
      float: right;
      color: #999;
      font-size: 14px;
    }
  }

  .line_2 {
    height: 30px;
    font-size: 14px;
    color: #999;
    /deep/ * {
      display: block;
      background: transparent !important;
      color: #999 !important;
      font-size: 14px !important;
      font-family: "微软雅黑" !important;
      line-height: 30px !important;
    }
  }
}
</style>